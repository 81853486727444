/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  font-family: 'Roboto Slab', serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --ck-z-default: 100;
  --ck-z-panel: calc(var(--ck-z-default) + 999);
}

code {
  font-family: 'Roboto Slab', serif;
}

a {
  color: black !important;
  text-decoration: none !important;
}

li {
  list-style: none;
}

.btn-dark {
  background-color: #468FD3 !important;
  border-color: #468FD3 !important;
}

.btn-dark:hover {
  background-color: #77a7d4 !important;
  border-color: #77a7d4 !important;
}

.btn-info {
  background-color: #F16F32 !important;
  border-color: #F16F32 !important;
  color: #fff !important;
}

.btn-info:hover {
  background-color: rgba(241, 111, 50, 0.5) !important;
  border-color: rgba(241, 111, 50, 0.3) !important;
}

.btn-outline-dark {
  background-color: #fff !important;
  border-color: #468FD3 !important;
}

.btn-outline-dark:hover {
  background-color: unset !important;
  color: #468FD3 !important;
}

.btn-outline-info {
  background-color: #fff !important;
  border-color: #F16F32 !important;
  color: #F16F32 !important;
  cursor: default !important;
}

/* .btn-outline-info:hover {
  background-color: unset !important;
  color: #F16F32 !important;
} */

.Toastify__toast-container--top-right {
  right: 0em !important;
  padding: 0;
}

.message-success .Toastify__progress-bar-theme--light {
  background: rgb(55 120 107) !important;
}

.message-error .Toastify__progress-bar-theme--light {
  background: rgb(149 77 69) !important;
}

.Toastify__close-button {
  align-self: center !important;
}

.message {
  width: 100%;
  font-size: 15px;
  color: #fff;
  margin-top: 0px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.message-error {
  color: #E9EDEF;
  background-color: rgb(231, 76, 60) !important;
}

.message-success {
  color: #ffffff;
  background-color: rgb(38, 185, 154) !important;
}

.wrapper,
.home-page-holder {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* MENU */
.navbar {
  position: sticky !important;
  top: 0;
  background-color: #fff;
  z-index: 10;
}

.navbar-container {
  padding: 0 50px !important;
}

.nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-nav-icon {
  cursor: pointer;
}

.desktop-navbar {
  margin-right: 20px;
}

.collapsable-navbar,
.collapsable-filters {
  background: rgb(255, 255, 255);
  position: fixed;
  right: 0;
  left: 0;
  top: 76px;
  width: 100%;
  z-index: 10;
  transition: all 0.75s ease-in-out 0s;
  margin: 0 0 0 100%;
  padding: 10px;
}

.menu-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 17px;
}

.show-nav,
.show-filters {
  margin-left: 0;
}

.home-page-holder .video-hide-nav.navbar {
  background-color: transparent;
  color: #fff;
}

.home-page-holder .video-hide-nav.navbar .nav-item a {
  color: #fff !important;
}

.mobile-search-form {
  font-size: 14px;
  padding: 10px;
  color: initial;
  width: 100%;
  background: rgb(255, 255, 255);
  position: fixed;
  top: 76px;
  right: 0;
  transition: all 0.75s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
  z-index: -10;
}

.home-page-holder .video-hide-nav.navbar .mobile-search-form .search-box {
  border-bottom: 1px solid rgba(2, 2, 2, 0.1);
  color: initial;
  width: 100%;
}

.home-page-holder .video-hide-nav.navbar .mobile-search-form .search-box::placeholder {
  color: initial;
  font-size: 14px;
}

.toggle-nav-icon:hover,
.logout-icon:hover,
.contact-holder span a:hover {
  color: #F16F32 !important;
}

.custom-list-show,
.logout-button-show,
.mobile-search-form-show {
  opacity: 1;
  visibility: visible;
  z-index: 2;
}

.logout-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  max-width: 150px;
  justify-content: space-between;
  padding: 10px;
  color: initial;
  border-radius: 0;
  background: rgb(255, 255, 255);
  position: fixed;
  top: 90px;
  width: 20%;
  transition: all 0.75s ease-in-out 0s;
  height: fit-content;
}

.logout-icon {
  cursor: pointer;
}

.icons-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.media-yt {
  color: red !important;
}

.media-fb {
  color: #316FF6 !important;
  font-size: 22px;
}

.media-in {
  background: linear-gradient(to right, #8a3ab9, #c13584, #e1306c, #fd1d1d) !important;
  color: #fff !important;
  font-size: 21px;
  width: 22px;
  border-radius: 20%;
}

.navbar-user {
  margin: 0 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.navbar-icon {
  margin: 0 10px;
  cursor: pointer;
}

.cart-icon-container {
  position: relative;
}

.cart-size {
  font-size: 10px;
  border-radius: 50%;
  background-color: #F16F32;
  display: inline-block;
  height: 15px;
  width: 15px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 0;
}

.nav-link:hover,
.navbar-icon:hover,
.search-icon:hover,
.product-category:hover {
  color: #F16F32 !important;
}

.search-icon {
  cursor: pointer;
}

.search-container {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.home-page-holder .video-hide-nav.navbar .search-box {
  border-bottom: 1px solid #fff;
  color: #fff;
}

.home-page-holder .video-hide-nav.navbar .search-box::placeholder {
  color: #fff;
}

.search-box {
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(2, 2, 2, 0.1);
  background: transparent;
  font-size: 15px;
}

.search-box::placeholder {
  font-size: 15px;
}

/* CATALOG*/
.main-contaner {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  flex: 1 0;
  /* overflow-x: hidden; */
}

.chekboxes-container {
  position: sticky;
  left: 0;
  top: 200px;
  height: fit-content;
}

.categories-container {
  border-right: 1px solid rgba(2, 2, 2, 0.1);
}

.filters-header {
  padding-bottom: 5px;
  border-bottom: 1px solid;
  width: 95%;
  display: inline-block;
}

.filters {
  margin: 10px 0;
}

.category-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-size: 13px;
  text-transform: uppercase;
}

.checkbox-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-holder input {
  cursor: pointer;
  margin-right: 10px;
}

.products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.catalog-product {
  height: 490px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
  box-shadow: 0 3px 35px 0 #cecece;
}

.product-page-details:hover,
.logout-button:hover {
  box-shadow: 0 3px 35px 0 #cecece;
}

.product-name-holder {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccccccb2;
  margin: 0 15px;
}

.product-name {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.catalog-product-loyalty {
  font-size: 40px;
  position: relative;
  cursor: default;
}

.catalog-product-loyalty-num {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  color: rgb(255, 191, 0);
}

span.catalog-product-loyalty-num {
  text-decoration: none !important;
  color: #000;
  font-weight: 600;
}

.user-loyalty-info .catalog-product-loyalty-num {
  top: 65%;
  left: 32%;
}

.user-loyalty-info span.catalog-product-loyalty-num {
  font-size: 15px;
}

.loyalty-info-span {
  font-size: 16px;
  font-weight: 600;
}

.sale-price {
  align-items: center;
  padding: 15px 0;
  width: 100%;
}

.old-price {
  color: #F16F32;
}

.catalog-shop {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sale-price p,
.catalog-price p {
  font-size: 19px;
  font-weight: 700;
  margin-bottom: 0;
}

.sale-price span {
  text-decoration: line-through;
  font-size: 15px;
}

.input-qty-add-item {
  width: 100%;
  text-align: center;
  padding: 7.5px 0;
  border-bottom-left-radius: 14px;
  border: none;
  background-color: #F4EDEA;
  font-weight: 600;
}

input.input-qty-add-item:focus {
  outline: none;
}

.shop-button {
  width: 35%;
  border-radius: unset !important;
  border-bottom-right-radius: 13px !important;
  padding: 6.5px !important;
}

.not-available {
  font-size: 17px !important;
  width: 100%;
  margin: 0;
  border-radius: unset !important;
  border-bottom-left-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
  cursor: auto !important;
  color: #468FD3 !important;
  padding: 6px !important;
}

.catalog-image {
  margin-top: 45px;
  padding: 10px;
  overflow: hidden;
  position: relative;
}

.catalog-image:hover .original-img {
  opacity: 0;
}

.catalog-image:hover .hover-img {
  opacity: 1;
  transform: translateX(-50%) scale(1.25) !important;
  transition: all 0.5s ease-in-out 0s;
}

.product-img {
  max-height: 250px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.5s ease-in-out 0s;
}

.badges-holder {
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 0;
}

.badge-item {
  justify-content: center;
  padding: 5px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.new-badge {
  background-color: #F16F32;
}

.sale-badge {
  background-color: red;
}

.soon-badge {
  background-color: #468FD3;
}

.percentage-icon {
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  color: #F16F32;
  margin-right: 10px;
  height: 8px;
  width: 8px;
}

.pagination-custom {
  display: flex;
  justify-content: center;
}

.dropdown-holder {
  position: sticky;
  top: 95px;
  background-color: #fff;
  z-index: 1;
}

.show-articles-container {
  display: flex;
  /* flex-direction: row;
  align-items: center; */
  justify-content: flex-end;
  margin-bottom: 20px;
}

.sort-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.show-articles-container span {
  margin-right: 10px;
}

.show-articles {
  width: fit-content !important;
  margin-right: 20px;
}

/* PRODUCT */

.product-page-banner-holder {
  box-shadow: 0 3px 35px 0 #cecece;
  border-radius: 15px;
  height: 400px;
  padding: 0 !important;
}

.product-page-banner-holder img {
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  border-radius: 15px;
  height: 100%;
}

.product-page-image {
  margin-left: auto;
  margin-right: auto;
  width: 450px !important;
}

.product-page-image img {
  background: white;
}

.product-info-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.product-page-name {
  font-size: 42px;
  padding: 0 !important;
}

.product-page-details {
  border: 1px solid #ccc;
  border-radius: 15px;
}

.product-page-details .tab-links {
  width: 100%;
  margin: initial;
  cursor: initial;
}

.product-page-details .tab-link {
  font-size: 16px;
  padding: 10px;
  font-weight: 700;
}

.product-page-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  padding-left: 30px;
}

.product-page-info img {
  height: 35px;
  width: 35px;
  margin-right: 15px;
}

.product-page-info span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.second-mini-slider {
  width: 450px !important;
  margin: 0 auto;
  margin-top: 50px !important;
}

.second-mini-slider-1 {
  width: 120px !important;
}

.second-mini-slider-2 {
  width: 225px !important;
}

.second-mini-slider-3 {
  width: 338px !important;
}

.item-list-slider-product {
  position: relative;
}

.second-mini-slider .item-mini-images-product {
  border: 2px solid #ebebeb;
  border-radius: 6px;
  margin-bottom: 6px;
  background-color: #fff;
  margin-right: 5px;
  padding: 6px;
  position: relative;
}

.second-mini-slider .slick-current .item-mini-images-product {
  border-color: #F16F32;
}

.slick-disabled {
  background-color: rgba(241, 111, 50, 0.5) !important;
  cursor: auto !important;
}

.mini-images-slider-product {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  -webkit-transform: none !important;
  transform: none !important;
  height: 55px;
  width: auto !important;
  max-width: 100%;
  margin: 0 auto;
  transition: all .3s ease;
}

.product-price {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.product-price p {
  font-size: 32px;
  margin-bottom: 0;
  color: #F16F32;
  margin-right: 10px;
}

.product-old-price {
  color: #C5C5C5;
  font-size: 18px;
  text-decoration: line-through;
}

.product-qty-buy {
  display: flex;
}

.product-qty-buy .input-qty-add-item {
  width: 58px;
  height: 48px;
  background-color: initial;
  border-bottom-left-radius: 0;
  border: 1px solid #DDD;
  padding: 0;
  border-radius: 6px;
}

.product-qty-buy .btn-info {
  height: 48px;
  width: 197px;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 16px;
}

.product-basic-info li {
  list-style-type: disc;
  line-height: 30px;
}

.product-basic-info li::marker {
  color: #F16F32;
  width: 6px;
  height: 6px;
}

.product-basic-info li,
.product-basic-info small {
  font-size: 14px;
}

.product-basic-info li span {
  font-weight: 700;
}

.product-availability-true {
  color: #009900;
  font-weight: 400 !important;
}

.product-availability-false {
  color: #D43343;
  font-weight: 400 !important;
}

.small-play-icon {
  position: absolute;
  top: 32%;
  left: 36%;
  width: 30px;
  height: 30px;
  color: #fff;
}

.product-category {
  margin: 0 5px;
  font-weight: 400 !important;
  text-decoration: underline;
}

.tabs-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 20px !important;
}

.tab-links {
  margin: 15px 10px 0 0;
  width: 312px;
  text-align: center;
  cursor: pointer;
  background-color: #F4EDEA;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.tab-links-active {
  background-color: #468FD3;
  color: #fff;
}

.tab-link {
  padding: 10px 10px 5px 10px;
  font-size: 15px;
  font-weight: 600;
}

.product-page-desc {
  border: 1px solid #ccc;
  padding: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.similar-products-slider h4 {
  border-bottom: 2px solid #F16F32;
}

.accessories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;
}

.similar-products-slider {
  margin-top: 50px;
}

.similar-products-slider .slick-list {
  padding: 40px 20px;
}

.similar-products-slider h4 {
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.similar-products-slider .catalog-product {
  width: 285px;
  height: 476px;
  border-radius: 15px;
}

.similar-products-slider .product-img {
  max-height: 230px;
  max-width: 100%;
}

.hover-img {
  position: absolute;
  top: 10px;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%) scale(1) !important;
}

/* CART */

.offcanvas-body {
  overflow-x: hidden;
}

.cart-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 3px !important;
  border-bottom: 1px solid #ccccccb2;
}

.cart-img-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cart-price-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.cart-img {
  /* height: auto; */
  max-height: 100px;
  width: auto;
  /* max-width: 100px; */
}

.cart-item-info {
  margin-left: 10px;
}

.cart-item-name {
  font-weight: 600;
  margin-bottom: 10px;
  max-width: 170px;
}

.cart-item-price {
  display: flex;
  flex-direction: column;
}

.qty-times {
  display: none;
}

.cart-price {
  font-size: 15px;
  color: #468FD3;
  font-weight: 400;
}

.cart-item-price small {
  text-decoration: line-through;
  font-size: 13px;
}

.cart-qty {
  position: relative;
  width: 45%;
  margin-right: 10px;
}

.qty-button {
  position: absolute;
  top: 1px;
  background-color: unset;
  border: none;
  color: black;
  padding: 5px;
  height: 35px;
  width: 35px;
  font-size: 16px;
  font-weight: 700;
}

.plus-item-qty {
  right: 0;
}

.cart-qty .input-qty-add-item {
  background-color: transparent;
}

.cart-delete-item {
  font-size: 16px;
  cursor: pointer;
}

.price-sum {
  padding: 30px 0;
}

.price-sum-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px !important;
}

.cart-button {
  height: 80px;
  font-size: 18px;
  font-weight: 600;
  background: #F16F32;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.cart-button p {
  margin: 0;
  color: #000 !important;
}

button.cart-button {
  border: none;
}

.navigation-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
}

.navigation-holder a:hover {
  color: #F16F32;
}

.navigation-holder p {
  color: #468FD3;
}

.cart-header {
  font-size: 80px;
  line-height: 72px;
}

.big-cart-info {
  background-color: #e2e4ef;
  text-transform: uppercase;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.active-cart-tab {
  color: #fff;
  background-color: #468FD3;
}

.big-cart-info span {
  font-size: 13px;
  font-weight: 700;
  margin-left: 10px;
}

.big-cart-items .cart-item-name {
  font-size: 19px;
  display: inline-block;
  overflow: unset;
  text-overflow: unset;
  max-width: none;
}

.big-cart-items .cart-qty {
  width: 30%;
}

.big-cart-items .qty-times,
.big-cart-items .cart-price {
  display: inline;
  color: #468FD3;
  font-weight: 600;
  font-size: 17px;
}

.big-cart-items .qty-button,
.big-cart-items .input-qty-add-item,
.big-cart-items .cart-delete-item {
  font-size: 18px;
}

.big-cart-items small {
  font-size: .875em;
}

.big-cart-sum {
  background-color: #e2e4ef;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  height: fit-content;
}

.big-cart-sum h2 {
  font-size: 32px;
  line-height: 36px;
}

.big-cart-sum .price-sum-item {
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 14px;
  margin-top: 20px !important;
}

.big-cart-sum .price-sum {
  font-size: 14px;
  padding: 0;
  margin-top: 35px;
}

.loyalty-points {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.big-cart-price {
  font-weight: 700;
  font-size: 16px;
}

.big-cart-sum .cart-button {
  width: 90%;
  height: 60px;
  margin-top: 30px;
  justify-content: space-evenly;
  cursor: pointer;
}

.cart-back-button {
  width: 20% !important;
  background-color: #fff;
  margin-right: 20px;
}

.cart-data-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  margin: 30px auto;
}

.cart-data-button {
  font-size: 16px;
  font-weight: 700;
  border: 1px solid #ccccccb2;
  border-radius: 50px;
  cursor: pointer;
  width: 30%;
}

.payment-notice {
  font-size: 16px;
  font-weight: 700;
  /* height: 65px; */
  background-color: #e2e4ef;
}

.payment-options {
  box-shadow: 0 3px 35px 0 #cecece;
  border-radius: 15px;
  padding: 15px;
}

.payment-option {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccccccb2;
}

.payment-check {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.form-check-label span {
  font-weight: 600;
}

.cart-check .cart-price-holder {
  display: none;
}

.cart-check .cart-item {
  position: relative;
}

.cart-check .cart-item-price {
  position: absolute;
  right: 5px;
  top: 42%;
}

.cart-check .cart-item-name {
  margin: 0;
}

.cart-check .payment-notice {
  font-weight: 400;
}

.cart-check span {
  cursor: pointer;
  color: #468FD3;
}

.cart-check-data {
  border-bottom: 1px solid #ccccccb2;
}

/* HOMEPAGE*/
/* .body-has-video-container::-webkit-scrollbar {
  width: 0;
} */

.video-container {
  padding: 0 !important;
  overflow-x: hidden;
  margin-top: -126px;
  flex: 1 0;
}

.home-contaner {
  padding: 0 30px !important;
}

.heading-container {
  position: relative;
}

h1 {
  position: relative;
  display: inline-block;
  padding: 0 20px;
}

.home-contaner h1::before,
.home-contaner h1::after {
  content: "";
  position: absolute;
  height: 1px;
  width: 38%;
  background-color: #000;
}

.home-contaner h1::before {
  top: 50%;
  left: 15px;
}

.home-contaner h1::after {
  top: 50%;
  right: 15px;
}

.home-page-slider h4 {
  display: none;
}

.home-page-slider .similar-products-slider {
  margin-top: 5px;
  padding: 0;
}

/* .home-page-slider .slick-list {
  padding: 40px 20px;
} */

.home-page-slider .similar-products-slider .catalog-product {
  width: 330px;
  height: 490px;
}

.home-page-slider .similar-products-slider .product-img {
  max-height: 250px;
}

.home-page-banner-holder,
.blogs-holder {
  box-shadow: 0 3px 35px 0 #cecece;
  border-radius: 15px;
  height: 512px;
}

.home-page-banner-holder * {
  height: 100% !important;
}

.home-page-banner-holder img {
  object-fit: cover;
  width: 100%;
  max-height: 512px;
  border-radius: 15px;
}

.home-page-banner-holder ul.slick-dots {
  bottom: 15px;
  height: initial !important;
}

.home-page-banner-holder .slick-dots li button {
  border: 2px solid #fff;
  border-radius: 50%;
  height: 13px !important;
  width: 13px !important;
}

.home-page-banner-holder .slick-active button {
  border-color: #F16F32 !important;
}

.home-page-banner-holder .slick-dots li button:before {
  color: transparent !important;
}

.blogs-holder {
  overflow-y: auto;
  padding: 15px;
}

.blogs-holder h3 {
  font-size: 25px;
  color: grey;
}

.blog-item {
  border-top: 3px solid #ebecf1;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
}

.blog-item h4 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-item a:hover {
  color: #F16F32 !important;
}

.blog-text {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* USER MODAL */
.modal-title {
  margin: 0 auto;
}

.close-modal,
.forgot-pass {
  cursor: pointer;
}

.forgot-pass {
  font-size: 13px;
  color: red;
}

.login-button {
  font-size: 18px !important;
  font-weight: 600 !important;
  width: 100%;
}

.registration-button {
  background-color: #F16F32 !important;
  border-color: #F16F32 !important;
}

.registration-button:hover {
  background-color: rgba(241, 111, 50, 0.5) !important;
  border-color: rgba(241, 111, 50, 0.5) !important;
}

.policy-check a:hover {
  text-decoration: underline !important;
  color: #F16F32 !important;
}

.not-valid-policy-check,
.not-valid-policy-check a {
  color: #dc3545 !important;
}

/* PROMENI LOZINKU PAGE*/
.change-pass-holder {
  height: 700px;
  position: relative;
}

.vertical-aligment {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* FOOTER */
.footer-container {
  background-color: #F4EDEA;
  border-top: 1px solid #F16F32;
  margin-top: 5rem;
  overflow: hidden;
}

.footer-wrapper {
  padding: 40px 50px;
}

.footer-main {
  display: flex;
  justify-content: space-between;
}

.footer-main h6 {
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}

.footer-main span {
  display: block;
  margin-bottom: 3px;
}

.footer-socials h6 {
  font-weight: 600;
  margin-bottom: 15px;
}

.footer-socials ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.footer-socials ul li {
  background-color: #fff;
  border-radius: 50%;
  margin-right: 10px;
  transition: .3s;
}

.footer-socials ul li:hover {
  background-color: #f1f1f1;
}

.footer-main .footer-socials ul li:last-child {
  margin-right: 0;
}

.footer-main .footer-nav ul {
  padding: 0;
  margin-bottom: 0;
}

.footer-main .footer-nav ul li {
  list-style: inside;
  margin-bottom: 4px;
}

.footer-main .footer-nav ul li::marker {
  color: #F16F32;
}

.footer-main .footer-nav ul li a {
  color: #212529 !important;
  transition: .3s;
}

.footer-main .footer-nav ul li a:hover {
  color: #F16F32 !important;
  text-decoration: underline !important;
}

.footer-main .footer-contact p {
  margin-bottom: 3px;
}

.footer-main .footer-contact a {
  color: #212529 !important;
}

.footer-main .footer-contact a span {
  display: inline-block;
  text-decoration: underline;
  transition: .3s;
}

.footer-main .footer-contact a span:hover {
  color: #F16F32;
}

.footer-copy {
  text-align: center;
  color: #fff;
  background-color: #F16F32;
  padding: 15px 0;
  align-items: center;
}

.footer-copy small {
  font-size: 13px;
  letter-spacing: 1px;
}

.bank-branding-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.bank-branding {
  height: 38px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
}

.payment-option .bank-branding-holder {
  justify-content: start;
}

.payment-option .bank-branding {
  height: 24px;
  width: 38px;
}

.bank-branding img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

/* BLOGS */
.blogs-main .blogs-page-banner {
  background: url(../public/bg-blogs.jpg) center top no-repeat;
  background-size: auto 100%;
  height: 520px;
  display: flex;
  align-items: center;
  margin-bottom: 5em;
}

.blogs-main .blogs-page-banner h1 {
  display: block;
  text-align: center;
  font-size: 80px;
  color: #fff;
  padding: 0;
  margin-bottom: 10px;
}

.blogs-main .blogs-page-banner p {
  font-size: 32px;
  text-align: center;
  color: #fff;
  margin-bottom: 0;
}

.blog-page-banner-holder {
  padding: 0 !important;
}

.blog-page-banner-holder img {
  object-fit: cover;
  width: 100%;
  max-height: 400px;
  border-radius: 15px;
  height: 100%;
}

.blog-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  box-shadow: 0 3px 35px 0 #cecece;
  border-radius: 15px;
  max-width: 550px;
  margin: 0 auto;
}

.blog-image {
  max-height: 365px;
  width: 100%;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.blog-info {
  background-color: #F4EDEA;
  padding: 20px 20px 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.blog-info h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1rem;
}

.blog-info .read-more-cta {
  position: relative;
  color: #F16F32;
  transition: .3s;
  margin-top: 1rem;
}

.blog-info .read-more-cta::after {
  content: url(/public/arrow-right.svg);
  position: absolute;
  top: 1px;
  margin-left: 10px;
}

.blog-info .read-more-cta:hover {
  color: #000;
}

.blog-info a:hover {
  color: #F16F32 !important;
}

.blog-info span,
.cart-item-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-item-image {
  max-height: 400px;
}

/* SEARCH PAGE */
.search-page-header {
  border-bottom: 1px solid rgba(2, 2, 2, 0.1);
}

.search-page-box {
  font-size: 20px;
  width: 30%;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccccccb2;
}

.search-page-box::placeholder {
  font-size: 20px;
}

/* SCROLL */
.back-to-top {
  background: #468FD3;
  position: fixed;
  bottom: 35px;
  right: 10px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 0;
  color: #fff;
  z-index: 3;
  transition: all .1s ease-in-out;
  display: none;
  font-size: 20px;
}

.back-to-top.active {
  display: block;
  opacity: 1;
  transition: all .2s ease-in-out;
}

/* ADMIN */
.table-input {
  border: none;
  outline: none;
  background: transparent;
  width: 20%;
  border-bottom: 1px solid rgba(2, 2, 2, 0.1);
}

.admin-img {
  max-height: 200px;
  padding: 10px;
}

.admin-minus-holder {
  position: relative;
}

.admin-minus {
  font-size: 20px;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

/* ABOUT US */
.about-container {
  padding-bottom: 0 !important;
}

.about-main h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.about-banner {
  background: url(../public/bg-about.jpg) center top no-repeat;
  background-size: auto 100%;
  height: 520px;
  display: flex;
  align-items: center;
}

.about-title {
  text-align: center;
  color: #fff;
}

.about-title h1 {
  display: block;
  font-size: 80px;
  padding: 0;
  margin-bottom: 10px;
}

.about-title p {
  font-size: 32px;
  margin-bottom: 0;
}

.about-main .section-1 {
  padding: 5rem 0;
}

.section-1 .row,
.section-2 .row {
  display: flex;
  align-items: center;
}

.about-text p:nth-child(3) {
  margin-bottom: 0;
}

.about-main .about-img {
  box-shadow: 0 3px 35px 0 #cecece;
  border-radius: 30px;
}

.section-3 {
  padding: 5rem 0;
}

.section-3 .contact-title {
  text-align: center;
}

.section-3 .contact-title p {
  padding: 0 20%;
  margin-bottom: 0;
}

.section-3 .contact-text {
  padding-left: 15%;
  margin-top: 5rem;
}

.section-3 .contact-text h3 {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #468FD3;
  margin-bottom: 1.5rem;
}

.section-3 .contact-text p {
  margin-bottom: 6px;
}

.section-3 .contact-text p:nth-child(3) {
  margin-bottom: 0;
}

.section-4 .google-map {
  background-color: #F4EDEA;
  height: 520px;
  border-top: 1px solid #F16F32;
}

/* RESPONSIVE KOS */

@media (max-width: 1680px) {
  .home-page-slider .similar-products-slider .catalog-product {
    width: 350px;
  }
}

@media (max-width: 1536px) {
  .home-page-slider .similar-products-slider .catalog-product {
    width: 330px;
  }
}

@media (max-width: 1440px) {

  .heading-container h1::before,
  .heading-container h1::after {
    width: 34%;
  }

  .home-page-slider .similar-products-slider .catalog-product {
    width: 310px;
  }

  /* ABOUT US */
  .about-title h1 {
    margin-bottom: 0;
  }
}

@media (max-width: 1366px) {

  /* HOMEPAGE */
  .video-container .mb-3 {
    margin-bottom: 48px !important;
  }

  .heading-container h1 {
    margin-top: 0 !important;
    margin-bottom: 10px !important;
  }

  .heading-container h1::before,
  .heading-container h1::after {
    width: 33%;
  }

  .home-page-slider .similar-products-slider .catalog-product {
    width: 290px;
  }

  /* VELIKA KORPA */
  .cart-header {
    font-size: 60px;
  }

  /* STRANICA PROIZVODA*/
  .similar-products-slider .catalog-product {
    width: 325px;
  }
}

@media (max-width: 1280px) {

  /* HOMEPAGE */
  .heading-container h1::before,
  .heading-container h1::after {
    width: 32%;
  }

  .home-page-banner-holder,
  .blogs-holder {
    height: 480px;
  }

  .home-page-slider .similar-products-slider .catalog-product {
    width: 370px;
  }

  /* ABOUT US */
  .section-3 .contact-text {
    padding-left: 14%;
  }

  /* STRANICA PROIZVODA*/
  .similar-products-slider .catalog-product {
    width: 325px;
  }
}

@media (max-width: 1024px) {

  /* HOMEPAGE */
  .heading-container h1 {
    font-size: 2.25rem;
  }

  .heading-container h1::before,
  .heading-container h1::after {
    width: 30%;
  }

  .home-page-slider .similar-products-slider .catalog-product {
    width: 280px;
    height: 480px;
  }

  .home-page-banner-holder,
  .blogs-holder {
    height: 412px;
  }

  /* VELIKA KORPA */
  .cart-header {
    font-size: 44px;
  }

  .big-cart-info {
    height: 57px;
  }

  .big-cart-sum h2 {
    font-size: 26px;
    line-height: 30px;
  }

  /* BLOGS */
  .blogs-main .blogs-page-banner {
    height: 420px;
    margin-bottom: 3rem;
  }

  .blog-image {
    max-height: 270px;
  }

  /* ABOUT US */
  .about-banner {
    height: 420px;
  }

  .about-main h2 {
    font-size: 32px;
  }

  .section-3 .contact-text {
    padding-left: 12%;
  }

  .section-3 .contact-text h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  /* STRANICA PROIZVODA*/
  .similar-products-slider .catalog-product {
    width: 280px;
  }
}

@media (max-width: 834px) {

  /* HOMEPAGE */
  .home-page-holder .video-hide-nav .navbar-toggler {
    color: #fff !important;
    border-color: #fff !important;
  }

  .home-page-holder .video-hide-nav.navbar .nav-item a {
    color: initial !important;
  }

  .home-page-holder .video-hide-nav .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .heading-container h1 {
    font-size: 2rem;
  }

  .heading-container h1::before,
  .heading-container h1::after {
    width: 27%;
  }

  .home-page-slider .similar-products-slider .catalog-product {
    width: 310px;
  }

  .home-page-banner-holder,
  .blogs-holder {
    height: 360px;
  }

  .blogs-holder {
    margin-bottom: 40px;
  }

  /* MALA KORPA */
  .cart-header {
    font-size: 40px;
  }

  .cart-img {
    max-height: 85px;
  }

  .cart-item-info {
    margin-left: 5px;
  }

  .cart-item-name {
    max-width: 150px;
  }

  /* VELIKA KORPA */
  .big-cart-info span {
    display: none;
  }

  .big-cart-items {
    padding: 0 20px;
  }

  .big-cart-items .cart-item-name {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 150px;
  }

  .big-cart-items .qty-times,
  .big-cart-items .cart-price {
    font-size: 15px;
  }

  .big-cart-items .cart-qty {
    width: 50%;
  }

  .payment-notice {
    font-size: 15px;
  }

  .payment-options {
    padding: 0;
  }

  .payment-option,
  .payment-check {
    font-size: 14px;
  }

  .big-cart-sum {
    width: 100%;
  }

  .cart-check .cart-item-price {
    top: 38%;
  }

  .big-cart-sum .cart-button {
    color: #000 !important;
  }

  /* KATALOG */
  .dropdown-holder {
    top: 76px;
    padding: 5px 0;
  }

  /* BLOGS */
  .blogs-main .blogs-page-banner {
    height: 325px;
    margin-bottom: 42px;
  }

  .blogs-main .blogs-page-banner h1 {
    font-size: 76px;
  }

  .blogs-main .blogs-page-banner p {
    font-size: 26px;
  }

  /* ABOUT US */
  .about-banner {
    height: 325px;
  }

  .about-title h1 {
    font-size: 76px;
  }

  .about-title p {
    font-size: 26px;
  }

  .about-main .section-1 {
    padding: 3rem 0;
  }

  .about-main .section-1 .col-lg-7 {
    order: -1 !important;
  }

  .about-main .section-1 .col-lg-7 div {
    justify-content: center !important;
    margin-bottom: 2rem;
  }

  .about-text h2 {
    text-align: center;
  }

  .about-text p {
    text-align: center;
    padding: 0 2rem;
  }

  .section-2 img {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .section-3 .contact-title p {
    padding: 0 10%;
  }

  .section-3 .contact-text {
    padding-left: 0;
    margin-top: 4rem;
  }

  .section-3 .contact-text .col-lg-4 {
    margin-bottom: 2rem;
    text-align: center;
  }

  .section-3 .contact-text .col-lg-4:last-child {
    margin-bottom: 0;
  }

  .section-3 .contact-text h3 {
    text-align: center;
  }

  /* FOOTER */
  .footer-wrapper {
    padding: 40px 12px;
  }

  .footer-main h6 {
    font-size: 14px;
  }

  .footer-main span {
    font-size: 14px;
  }

  .footer-nav {
    font-size: 14px;
  }

  .footer-contact p {
    font-size: 14px;
    text-align: left;
    padding: 0;
  }

  .footer-socials h6 {
    font-size: 14px;
  }
}

@media (max-width: 430px) {

  /* HOMEPAGE */
  .home-page-holder .video-hide-nav.navbar,
  .navbar {
    background-color: #fff;
    color: initial;
    font-size: 15px;
  }

  .home-page-holder .video-hide-nav.navbar .nav-item a,
  .navbar .nav-item a {
    color: initial !important;
    font-size: 15px;
  }

  .home-page-holder .video-hide-nav .navbar-toggler .navbar-toggler-icon {
    background-image: var(--bs-navbar-toggler-icon-bg);
  }

  .home-page-holder .video-hide-nav .navbar-toggler {
    border-color: rgba(0, 0, 0, 0.15) !important;
  }

  .navbar-user,
  .navbar-toggler {
    font-size: 17px !important;
  }

  .logout-button {
    font-size: 14px;
    width: 100%;
    top: 76px;
  }

  .video-container {
    margin-top: 0;
  }

  .home-contaner {
    padding: 0 5px !important;
  }

  .heading-container h1 {
    margin: 15px 0 !important;
    font-size: 20px;
  }

  .home-contaner h1::before,
  .home-contaner h1::after {
    width: 19%;
  }

  .home-page-slider .similar-products-slider .slick-list,
  .similar-products-slider .slick-list {
    padding: 10px 0;
  }

  .badges-holder {
    font-size: 12px;
  }

  .catalog-product {
    width: 290px;
    height: 445px;
  }

  .home-page-slider .similar-products-slider .catalog-product,
  .similar-products-slider .catalog-product {
    margin: 0 auto;
    width: 290px;
    height: 445px;
  }

  .home-page-slider .similar-products-slider .product-img,
  .similar-products-slider .product-img,
  .product-img {
    max-height: 200px;
  }

  .home-page-slider .similar-products-slider .product-name,
  .similar-products-slider .product-name,
  .product-name {
    font-size: 15px;
  }

  .home-page-slider .similar-products-slider .sale-price p,
  .similar-products-slider .sale-price p,
  .sale-price p {
    font-size: 17px;
  }

  .home-page-slider .similar-products-slider .sale-price span,
  .similar-products-slider .sale-price span,
  .sale-price span {
    font-size: 14px;
  }

  .home-page-slider .similar-products-slider .slick-arrow,
  .similar-products-slider .slick-arrow {
    padding: 0 !important;
  }

  .home-page-slider .similar-products-slider .slick-prev,
  .similar-products-slider .slick-prev {
    left: -1px !important;
  }

  .home-page-slider .similar-products-slider .slick-next,
  .similar-products-slider .slick-next {
    right: -1px !important;
  }

  .home-page-banner-holder {
    height: 220px;
  }

  .home-page-banner-holder img {
    max-height: 220px;
  }

  .blogs-holder {
    height: 250px;
    padding: 9px;
  }

  .blogs-holder h3 {
    font-size: 20px;
  }

  .blog-item h4 {
    font-size: 16px;
  }

  .blog-text {
    font-size: 14px;
  }

  .contact-holder,
  .info-holder {
    font-size: 11px;
  }

  .back-to-top {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }

  /* KATALOG */
  .main-contaner {
    padding-top: 0 !important;
  }

  .dropdown-holder {
    top: 76px;
    padding: 5px 0;
  }

  .show-articles-container {
    margin-bottom: 0;
    font-size: 14px;
    justify-content: space-between;
  }

  .show-articles-container span {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .sort-holder {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .sort-holder .btn {
    margin-bottom: 0 !important;
    font-size: 13px;
  }

  .chekboxes-container {
    overflow-y: auto;
    height: 300px;
  }

  /* BLOGS */
  .blog-page-banner-holder,
  .blogs-page-banner,
  .product-page-banner-holder {
    height: 220px;
  }

  .blog-page-banner-holder img,
  .blogs-page-banner img,
  .product-page-banner-holder img {
    max-height: 220px;
  }

  .blogs-main .blogs-page-banner {
    height: 435px;
  }

  .blogs-main .blogs-page-banner h1 {
    font-size: 66px;
  }

  .blogs-main .blogs-page-banner p {
    font-size: 24px;
  }

  /* MALA KORPA */
  .cart-img {
    max-height: 85px;
  }

  .cart-item-info {
    margin-left: 5px;
  }

  .cart-item-name {
    max-width: 150px;
  }

  /* VELIKA KORPA */
  .cart-header {
    font-size: 40px;
  }

  .big-cart-info span {
    display: none;
  }

  .big-cart-items {
    padding: 0 20px;
  }

  .big-cart-items .cart-item-name {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-width: 150px;
  }

  .big-cart-items .qty-times,
  .big-cart-items .cart-price {
    font-size: 15px;
  }

  .big-cart-items .cart-qty {
    width: 50%;
  }

  .payment-notice {
    font-size: 15px;
  }

  .payment-options {
    padding: 0;
  }

  .payment-option,
  .payment-check {
    font-size: 14px;
  }

  .cart-check .cart-item-price {
    top: 38%;
  }

  .big-cart-sum .cart-button {
    color: #000 !important;
  }

  /* SEARCH PAGE */
  .search-page-header {
    font-size: 16px;
    margin-bottom: 0 !important;
  }

  .search-page-box {
    width: 70%;
    font-size: 16px;
  }

  /* ABOUT US */
  .about-banner {
    background: url(../public/bg-about-mob.jpg) center top no-repeat;
    height: 435px;
  }

  .about-main h2 {
    font-size: 30px;
  }

  .about-title h1 {
    font-size: 66px;
  }

  .about-title p {
    text-align: center;
  }

  .about-text h2 {
    text-align: left;
  }

  .about-text p {
    text-align: left;
    padding: 0;
  }

  .about-main .about-img {
    border-radius: 15px;
  }

  .section-3 .contact-title p {
    text-align: center;
    padding: 0;
  }

  .section-3 .contact-text {
    padding-left: 0;
    text-align: center;
  }

  .section-3 .contact-text p {
    text-align: center;
  }

  .section-3 .contact-text .col-lg-4 {
    margin-bottom: 3rem;
  }

  .section-3 .contact-text .col-lg-4:last-child {
    margin-bottom: 0;
  }

  /* FOOTER */
  .footer-main {
    display: block;
  }

  .footer-main h6 {
    font-size: 16px;
  }

  .footer-main span {
    font-size: 16px;
  }

  .footer-nav {
    font-size: 16px;
  }

  .footer-contact p {
    font-size: 16px;
  }

  .footer-socials h6 {
    font-size: 16px;
    margin-top: 24px;
  }

  /* STRANICA PROIZVODA */
  .product-page-image {
    width: 320px !important;
  }

  .second-mini-slider-3 {
    width: 260px !important;
  }

  .small-play-icon {
    left: 32%;
  }
}